import React, { Ref } from "react";
import SEO from "../../components/seo/seo";
import { PageParams } from "../../../types";
import Hero from "../../components/hero/hero";
import Section from "../../components/air-section/air-section";

import ServiceIconWebsite from "../../images/offer/service_icon_website.svg";
import ServiceIconECommerce from "../../images/offer/service_icon_e-commerce.svg";
import ServiceIconMobile from "../../images/offer/service_icon_mobile.svg";
import ServiceIconDesign from "../../images/offer/service_icon_design.svg";
import ServiceIconDesktop from "../../images/offer/service_icon_desktop.svg";
import ServiceIconSoftware from "../../images/offer/service_icon_software.svg";
import ServiceIconConsulting from "../../images/offer/service_icon_consulting.svg";
import ServiceIconInfrastructure from "../../images/offer/service_icon_infrastructure.svg";

import "./offer.scss";
import HeaderBig from "../../components/header-big/header-big";
import CallFooter from "../../components/call-footer/call-footer";
import ScrollButton from "../../components/scroll-button/scroll-button";
import { Link } from "gatsby";

class OfertaPage extends React.Component {

  language = "pl";
  scrollRef: Ref<HTMLElement>;
  constructor(public params: PageParams) {
    super(params);
    this.language = params.pageContext.language;
    this.scrollRef = React.createRef();
  }

  offerItems = [
    {
      name: "Strony internetowe </br>www",
      to: "/strony-internetowe",
      img: ServiceIconWebsite,
      alt: "Ikona okna przeglądarki ze stroną internetową",
      description: `<strong>Kompleksowe wykonanie stron www</strong>. 
      Projekty, kodowanie, administracja, pozycjonowanie i&nbsp;kampanie reklamowe Ad Words.`,
    },
    {
      name: "Sklepy internetowe / </br>e-commerce",
      to: "/sklepy-internetowe",
      img: ServiceIconECommerce,
      alt: "Ikona okna przeglądarki ze stroną internetową",
      description: `Funkcjonalne <strong>sklepy internetowe</strong> tworzone od podstaw
      lub na bazie wybranego szablonu graficznego.`,
    },
    {
      name: "Portale internetowe i&nbsp;platformy konkursowe",
      to: "/portale-internetowe",
      img: ServiceIconDesktop,
      alt: "Ikona monitora z mechanizmem",
      description: `Nowoczesne i&nbsp;intuicyjne <strong>portale www</strong>, projektowane 
      zgodnie z&nbsp;zasadami prawidłowego <strong>UX</strong> oraz <strong>UI.`,
    },
    {
      name: "Aplikacje webowe",
      to: "/aplikacje",
      img: ServiceIconSoftware,
      alt: "Ikona zębatki ze znakiem kodu </>",
      description: "<strong>Dedykowane rozwiązania i&nbsp;oprogramowanie</strong> dopasowane do indywidualnych oczekiwań klienta.",
    },
    {
      name: "Aplikacje mobilne",
      to: "/aplikacje-mobilne",
      img: ServiceIconMobile,
      alt: "Ikona smartfona </>",
      description: `Aplikacjie na <strong>urządzenia mobilne</strong>, tworzone na platformy
      <strong>Android</strong>, <strong>iOS</strong> oraz <strong>Windows
      Phone</strong>.`,
    },
    {
      name: "Projektowanie graficzne",
      to: "/projektowanie-graficzne",
      img: ServiceIconDesign,
      alt: "Ikona przedstawiająca logo w trakcie projektowania graficznego",
      description: `Kreacja, <strong>identyfikacja wizualna</strong>, grafika 3D, projekty 
      opakowań, animacja.`,
    },
    {
      name: "Budowa strategii i&nbsp;doradztwo techniczne",
      to: "/konsulting",
      img: ServiceIconConsulting,
      alt: "Ikona kierunkowskazu",
      description: `<strong>Kampanie marketingowe</strong>, audyty oprogramowania, 
      konsultacje techniczne, <strong>dokumentacja projektowa</strong>.`,
    },
    {
      name: "Stała obsługa klientów",
      to: "/administracja",
      img: ServiceIconInfrastructure,
      alt: "Ikona przedstawiająca grupę serwerów połączonych ze sobą",
      description: `Administracja, hosting, opieka nad gotowym produktem...`,
    },
    {
      name: "Inne usługi",
      to: "/kontakt",
      img: ServiceIconInfrastructure,
      alt:
        "Ikona przedstawiająca inne usługi związane z internetem online lub marketingiem",
      description: `Jeżeli szukasz innej usługi związanej z&nbsp;IT lub marketingiem. Skontaktuj się z&nbsp;nami, postaramy się pomóc!`,
    },
  ];
  render() {
    return (
      <>
        <SEO
          title="Oferta"
          description="Strony internetowe, aplikacje, aplikacje mobilne i platformy dedykowane dla firm i korporacji. ☎ +48 665 035 451"
          lang={this.language}
        />
        <Hero>
          <div className="container-xs header-padding">
            <h1>Oferta Aplinet</h1>
            <div className="header-subline">
              <h2 className="subtitle">
                Specjalizujemy się w&nbsp;programowaniu, usługach IT oraz
                marketingu online
              </h2>
            </div>
            <h4>
              Nasze usługi obejmują planowanie, prototypowanie
              i&nbsp;implementację projektów dla ambitnych marek.
            </h4>
          </div>
          <ScrollButton  style={{
            position: "absolute",
            left: "50%",
            bottom: "5%",
            transform: "translateX(-50%)"
          }} to={this.scrollRef} />

        </Hero>

        {/* -----------------------------------------` */}
        <Section className="container" ref={this.scrollRef}>
          <HeaderBig
            tag="h2"
            span="Planujemy, projektujemy, wdrażamy"
            headerText="Zrealizujemy wszystkie pomysły klientów"
          />
        </Section>
        <Section className="offer-section">
          <div className="container">
            <div style={{ marginLeft: "-2em", marginRight: "-2em" }}>
              <div className="r :w :c-4 :g :c-6:xs :c-12:xxs">
                {this.offerItems.map((item, index) => {
                  return (
                    <div className="item-wrapper" key={index}>
                      <div className="item r :a-t :w :c-12 ">
                        <Link
                          key={index}
                          title={item.name}
                          to={item.to}
                        >
                          <img
                            src={item.img}
                            alt={item.alt}
                            className="image"
                          />
                          <div className="content">
                            <h3
                              className="name"
                              dangerouslySetInnerHTML={{
                                __html: item.name,
                              }}
                            ></h3>
                            <p
                              className="description"
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Section>
        <CallFooter />
      </>
    );
  }
}

export default OfertaPage;
